import healthyQr from "@images/healthyQr.jpg"
import valentDiagnostics from "@images/valentdiagnostics.png"
import adImage from "@images/img.png"
import React, {PropsWithChildren} from "react"
import styled from "styled-components"
import {Outlet} from 'react-router-dom'
import {ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

const Template: React.FC<PropsWithChildren<{}>> = () => {
    return (
        <>
            <ToastContainer />
            <Container>
                <InfoContainer>
                    <ContentContainer>
                        <TopBar>
                            <img src={healthyQr}
                                 style={{
                                     height: 'var(--topbar-height)',
                                     maxWidth: 'var(--max-image-width)',
                                     marginLeft: '8px'
                                 }}
                                 alt={'healthyQrLogo'}
                            />
                            <img src={valentDiagnostics}
                                 style={{height: 'var(--topbar-height)', maxWidth: 'var(--max-image-width)'}}
                                 alt={'centerLogo'}/>
                        </TopBar>
                        <Outlet/>
                    </ContentContainer>
                </InfoContainer>
                <AdContainer>
                    <Ad src={adImage}/>
                </AdContainer>
            </Container>
        </>

    )
}

export default Template

const Container = styled.div`
  display: flex;
  height: 100%;
`

const InfoContainer = styled.div`
  height: 100%;
  flex-grow: 1
`

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: var(--topbar-height) 1fr;
  height: 100%;
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
`

const AdContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: var(--max-image-width);
`

const Ad = styled.img`
  align-self: flex-end;
  height: calc(100% - var(--topbar-height));
  max-width: var(--max-image-width);
`