import {logger} from '@helpers'
import axios, {AxiosResponse} from 'axios'
import qs from 'query-string'
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {makeHeader} from './helpers'
import {getAccessToken} from "@store/selectors"
import {toast} from "react-toastify"
import {BE_URL} from '@constants'

interface Options<F> {
    lazy?: boolean
    initialFilters?: F
    admin?: boolean
}

interface Parameters<F> {
    filters?: F
    id?: number | string
    afterPath?: string
    token?: string
}

export interface CallsOptions {
    afterPath?: string
    token?: string
}

interface HookObject<E = any, F = any> {
    result: E
    results: E[]
    total: number
    setResult: (e: E) => void
    setResults: Dispatch<SetStateAction<E[]>>
    get: (args?: Parameters<F>) => Promise<{ payload: E[]; total: number }>
    getById: (args?: Parameters<F>) => Promise<{ payload: E; total: number }>
    post: (values?: any, opts?: CallsOptions) => Promise<any>
    put: (id: any, values?: any, opts?: CallsOptions) => Promise<any>
    remove: (id: any, opts?: CallsOptions) => Promise<any>
    download: (filename: string, opts?: CallsOptions) => Promise<void>
    loading: boolean
    error: string
}

/**
 * Hook per la gestione delle chiamate rest autenticate verso il server
 * @template E tipo EntityObject, dice come è fatta l'entità
 * @template F tipo FilterObject, dice come sono fatti i possibili filtri di ricerca
 * @param url {string} path relativo a cui mandare wle richieste
 * @param options {Options} oggetto per la configurazione dell'hook
 * @param options.lazy {boolean} crea l'hook senza chiamare la get dell'url
 * @param options.initialFilters {Object} filtri iniziali per la get
 * @param options.admin {boolean} indica se all'endpoint fa parte degli endpoint per pannello di amministrazione
 * @returns {HookObject<E>}
 */

function useRest<E = any, F = any>(url: string, options: Options<F> = {}): HookObject<E, F> {
    const {lazy = true} = options
    const [total, setTotal] = useState(0)
    const [result, setResult] = useState<E>({} as E)
    const [results, setResults] = useState<E[]>([] as E[])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const accessToken = useSelector(getAccessToken)

    //region METHODS
    /**
     * Funzione per il Get all su un endpoint preimpostato
     *
     * @param args {Parameters} campo contenente varie personalizzazzioni per la richiesta
     * @param args.afterPath {string} path addizionale da aggiungere in coda all'url
     * @param args.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta (default false)
     *
     * @returns Promise<E[]>  in caso di successo il risultato è un array di entità richieste esegue anche il setResults(res.data)
     *                        in caso di errore fa il throw dell'errore
     */
    const get = useCallback(
        ({filters, afterPath, token}: Parameters<F> = {}): Promise<{
            payload: E[]
            total: number
        }> => {
            let completeUrl = `${BE_URL}/${url}`
            if (afterPath) completeUrl += afterPath
            if (filters && Object.keys(filters).length !== 0) {
                const filtersToSend: any = {}
                Object.entries(filters).forEach(([key, value]) => {
                    if (value === null || value === undefined || value === '') return
                    if (typeof value === 'object' && !Array.isArray(value) && value.length === 0) return
                    // il core si aspetta il punto per gli oggetti annidati, ma hook form se usi il punto ti crea un oggetto
                    // quindi su hook form va usato > al posto del punto nel nome del campo
                    // inoltre per i campi che iniziano con - si vuole una ricerca "like", da annotare con un * iniziale
                    const keyToSend = key.charAt(0) === '-' && !key.includes('@') ? `${key}`.substring(1) : key
                    filtersToSend[keyToSend.replace(/>/g, '.')] = key.charAt(0) === '-' ? `*${value}` : value
                })
                completeUrl += '?'
                completeUrl += qs.stringify(filtersToSend)
            }
            setLoading(true)

            // Se ha trovato l'entry nella cache si assicura di farla diventare una promise con resolve
            // in modo da poter utilizzare then catch e finally
            const getPromise = axios.get(completeUrl, makeHeader(token || accessToken))

            return getPromise
                .then(({data}) => {
                    setTotal(data.total)
                    setResults(data.payload)
                    return data
                })
                .catch((error) => {
                    setLoading(false)
                    setError(error.response.data.message)
                    toast.error(error.response.data.message || 'Server error')
                })
                .finally(() => setLoading(false))
        },
        [url, accessToken]
    )
    /**
     * Funzione per il Get One su un endpoint preimpostato
     *
     * @param args {Parameters} campo contenente varie personalizzazzioni per la richiesta
     * @param args.id {number | string} id dell'entità richiesta
     * @param args.afterPath {string} path addizionale da aggiungere in coda all'url
     * @param args.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta (default false)
     *
     * @returns Promise<E>  in caso di successo il risultato è l'entità richiesta esegue anche il setResult(res.data)
     *                      in caso di errore fa il throw dell'errore
     */
    const getById = useCallback(
        ({filters, id, afterPath, token}: Parameters<F> = {}): Promise<{
            payload: E
            total: number
        }> => {
            let completeUrl = `${BE_URL}/${url}`
            if (id) completeUrl += `/${id}`
            if (afterPath) completeUrl += afterPath
            if (filters) {
                completeUrl += '?'
                const arrayFilters: string[] = []
                Object.entries(filters).forEach(([key, value]) => {
                    if (value === null || value === undefined) return
                    arrayFilters.push(`${key}=${encodeURIComponent(value.toString())}`)
                    if (arrayFilters.length > 0) {
                        completeUrl += '&'
                        completeUrl += arrayFilters.join('&')
                    }
                })
            }
            logger.silly(`GET: ${completeUrl}`)
            setLoading(true)
            return axios
                .get(completeUrl, makeHeader(token || accessToken))
                .then(({data}) => {
                    setResult(data.payload)
                    return data
                })
                .catch((error) => {
                    setLoading(false)
                    setError(error.response.data.message)
                    toast.error(error.response.data.message || 'Server error')
                })
                .finally(() => setLoading(false))
        },
        [url, accessToken]
    )
    /**
     * Funzione per il Post su un endpoint preimpostato
     *
     * @param values {any} oggetto contenente i valori da inviare nella richiesta
     * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
     * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
     * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
     *
     * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
     */
    const post = useCallback(
        (values?: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
            let completeUrl = `${BE_URL}/${url}`
            if (opts.afterPath) completeUrl += opts.afterPath
            setLoading(true)
            logger.silly(`POST: ${completeUrl}`)
            return axios
                .post(completeUrl, values || null, makeHeader(opts.token || accessToken))
                .then(({data}) => {
                    toast.success('Success')
                    return data
                })
                .catch((error) => {
                    setLoading(false)
                    setError(error.response.data.message)
                    toast.error(error.response.data.message || 'Server error')
                    throw error
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        [url, accessToken]
    )
    /**
     * Funzione per il Put su un endpoint preimpostato
     *
     * @param id {any} id dell'entita da modificare
     * @param values {any} oggetto contenente i valori da inviare nella richiesta
     * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
     * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
     * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
     *
     * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
     */
    const put = useCallback(
        (id: any, values?: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
            let completeUrl = `${BE_URL}/${url}`
            if (id) completeUrl += `/${id}`
            if (opts.afterPath) completeUrl += opts.afterPath
            setLoading(true)
            logger.silly(`PUT: ${completeUrl}`)
            return axios
                .put(completeUrl, values || null, makeHeader(opts.token || accessToken))
                .then(({data}) => {
                    toast.success('Success')
                    return data
                })
                .catch((error) => {
                    setLoading(false)
                    setError(error.response.data.message)
                    toast.error(error.response.data.message || 'Server error')
                    throw error
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        [url, accessToken]
    )
    /**
     * Funzione per il Delete su un endpoint preimpostato
     *
     * @param id {any} id dell'entita da cancellare
     * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
     * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
     * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
     *
     * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
     */
    const remove = useCallback(
        (id: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
            let completeUrl = `${BE_URL}/${url}`
            if (id) completeUrl += `/${id}`
            if (opts.afterPath) completeUrl += opts.afterPath
            setLoading(true)
            logger.silly(`DELETE: ${completeUrl}`)
            return axios
                .delete(completeUrl, makeHeader(opts.token || accessToken))
                .then(({data}) => {
                    toast.success('Success')
                    return data
                })
                .catch((error) => {
                    setLoading(false)
                    setError(error.response.data.message)
                    toast.error(error.response.data.message || 'Server error')
                    throw error
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        [url, accessToken]
    )
    /**
     * Funzione per il Download su un endpoint preimpostato
     *
     * @param id {any} id dell'entita da cancellare
     * @param fileName {string} nome del file da salvare
     * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
     * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
     * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
     *
     * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
     */
    const download = useCallback(
        (filename: string, opts: CallsOptions = {}): Promise<void> => {
            let completeUrl = `${BE_URL}/${url}`
            if (opts.afterPath) completeUrl += opts.afterPath

            return axios
                .get(completeUrl, makeHeader(opts.token || accessToken, 'blob'))
                .then(({data}) => {
                    const url = window.URL.createObjectURL(new Blob([data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${filename}`)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    toast.success('Success')
                    setTotal(1)
                })
                .catch((error) => {
                    setLoading(false)
                    setError(error.response.data.message)
                    toast.error(error.response.data.message || 'Server error')
                    throw error
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        [url, accessToken]
    )
    //endregion

    useEffect(() => {
        if (lazy) return
        get({filters: options.initialFilters}).catch((err) => logger.error(err))
    }, [get, lazy, options.initialFilters])

    return {
        get,
        getById,
        post,
        put,
        remove,
        download,
        result,
        results,
        total,
        setResult,
        setResults,
        loading,
        error
    }
}

export default useRest
