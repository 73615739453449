import React from 'react'
import styled from 'styled-components'
import {Typography} from '@mui/material'

const NotFound: React.FC = () => {
  return <Container>
    <Typography variant={'subtitle1'} sx={{fontSize: '3rem'}}>
      Pagina di visualizzazione dei referti
    </Typography>
    <Typography variant={'subtitle2'}>
      Cliccare sul link ricevuto tramite email per visualizzare il proprio esame
    </Typography>
  </Container>
}

export default NotFound

// region Style
const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
// endregion
