import React from 'react'
import './App.css'
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import PersonalArea from "./components/pages/PersonalArea"
import NotFound from "./components/pages/NotFound"
import OTPAccess from "./components/pages/OTPAccess"
import Template from "./components/template/Template"


function App() {
    return (
        <BrowserRouter>
            <Routes>
              <Route path={'/'} element={<Template/>}>
                <Route path="/personal-area" element={<PersonalArea />}/>
                <Route path="/patient" element={<OTPAccess />}/>
                <Route path="/" element={<NotFound />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
