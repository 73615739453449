import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import applicationState from './applicationState/reducer'


const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = persistReducer(persistConfig, combineReducers({
  applicationState
}))

const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const middlewares: any = []
middlewares.push(thunk)

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true })
  middlewares.push(logger)
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))
const persistor = persistStore(store)

const reduxStore = {store, persistor}

export default reduxStore
