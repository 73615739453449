import { AxiosRequestConfig, ResponseType } from 'axios'

/**
 * This TypeScript function creates a header object with an authorization token, optional response type, and optional
 * parameters.
 * @param {string} token - The `token` parameter is a string that represents an authentication token that will be used to
 * authorize the user's requests to the server.
 * @param {ResponseType} [responseType] - The `responseType` parameter is an optional parameter of type `ResponseType` that
 * specifies the type of response expected from the server. It can be one of the following values: "arraybuffer", "blob",
 * "document", "json", "text", or "stream". If this parameter is not
 * @param {any} [params] - The `params` parameter is an optional object that can be passed to the `makeHeader` function. It
 * represents any additional parameters that need to be included in the header of the HTTP request. These parameters can be
 * used to provide additional information to the server, such as query parameters or request body data
 * @returns The function `makeHeader` is returning an object of type `AxiosRequestConfig` which contains a `headers`
 * property with an `Authorization` key and a `Bearer` token value. It may also contain a `responseType` property and/or a
 * `params` property, depending on whether the `responseType` and `params` arguments were passed to the function.
 */
export const makeHeader = (token: string, responseType?: ResponseType, params?: any) => {
  const header: AxiosRequestConfig = {
    headers: { Authorization: 'Bearer ' + token }
  }
  if (responseType) {
    header['responseType'] = responseType
  }
  if (params) {
    header.params = params
  }
  return header
}
