export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'

//region Types

export interface ApplicationState {
  accessToken: string
}

//endregion

//region Actions

export interface SetAccessToken {
  type: typeof SET_ACCESS_TOKEN
  payload: string
}

export type ApplicationStateActionTypes =
  | SetAccessToken
//endregion
