import { Reducer } from 'redux'
import * as t from './types'

const initialState: t.ApplicationState = {
  accessToken: ''
}

type ApplicationStateReducer = Reducer<t.ApplicationState, t.ApplicationStateActionTypes>

const applicationStateReducer: ApplicationStateReducer = (
  state = initialState,
  action: t.ApplicationStateActionTypes
): t.ApplicationState => {
  switch (action.type) {
    case t.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload }
    default:
      return state
  }
}

export default applicationStateReducer
