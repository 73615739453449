import React, {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getAccessToken} from '@store/selectors'
import {useRest} from '@hooks'
import {Folder, Image} from '@api/types'
import {logger} from '@helpers'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Cookies from 'universal-cookie'
import {BE_URL, VIEWER_URL} from '@constants'
import axios from 'axios'
import {Backdrop, Button, CircularProgress, Tooltip} from '@mui/material'
import {Download, SimCardDownload, Visibility,} from '@mui/icons-material'

const PersonalArea: React.FC = () => {
  const navigate = useNavigate()
  const accessToken = useSelector(getAccessToken)
  const {getById: getFolder, result: folder, download: downloadFolder} = useRest<Folder>('patient-folders')
  const {get: generateViewerToken} = useRest<string>(`viewer-token`)
  const [downloading, setDownloading] = useState(false)
  const [downloadedMegabytes, setDownloadedMegabytes] = useState('0')

  /* `downloadPatientFolder` is a function that downloads a zip file containing all the images and medical reports of a
  patient's folder. It uses the `axios` library to make a GET request to the backend API endpoint
  `/patient-folders/download-folder`, passing the `accessToken` in the headers for authentication. It also sets an
  `onDownloadProgress` callback to track the progress of the download and update the state variable
  `downloadedMegabytes` accordingly. */
  const downloadPatientFolder = useCallback(() => {
    axios({
      method: 'get',
      url: `${BE_URL}/patient-folders/download-folder`,
      headers: {Authorization: 'Bearer ' + accessToken},
      onDownloadProgress: (progressEvent) => {
        setDownloadedMegabytes((progressEvent.loaded / (1024 * 1024)).toFixed(2))
      },
      responseType: 'arraybuffer'
    })
      .then((response) => {
        // Create a Blob object from the received arraybuffer data
        const blob = new Blob([response.data], {type: 'application/zip'})

        // Create a download link and trigger a download
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(blob)
        downloadLink.download = `${folder.name}_${folder.surname}.zip`
        downloadLink.click()
        setDownloading(false)
        setTimeout(() => setDownloadedMegabytes('0'), 500)
      })
      .catch((error) => {
        console.error('Error downloading archive:', error)
        setDownloading(false)
        setDownloadedMegabytes('0')
      })
    setDownloading(true)
  }, [folder.name, folder.surname, accessToken])

  /* `downloadMedicalReport` is a function that downloads the medical report of a patient's folder. It uses the `download`
  function returned by the `useRest` hook to make a GET request to the backend API endpoint
  `/patient-folders/download-medical-report`, passing the name and surname of the patient as part of the URL. The
  function is wrapped in a `useCallback` hook to memoize it and avoid unnecessary re-renders. If an error occurs during
  the download, it logs the error using the `logger.error` function. */
  const downloadMedicalReport = useCallback(() => {
    downloadFolder(`${folder.name}_${folder.surname}_medical_report.pdf`, {afterPath: '/download-medical-report'}).catch((e) => logger.error(e))
  }, [downloadFolder, folder.name, folder.surname])

  /* This `useEffect` hook is responsible for fetching the patient folder data and redirecting the user to the login page
  if there is no access token. It runs on mount and whenever the `getFolder`, `accessToken`, or `navigate` dependencies
  change. */
  useEffect(() => {
    if (!accessToken) navigate('/')
    getFolder().catch((e) => {
      logger.error(e)
    })
  }, [getFolder, accessToken, navigate])

  return (
    <Container>
      <Header>
        <PatientName>{`${folder.name} ${folder.surname}`}</PatientName>
        <ButtonsContainer>
          <Tooltip title={'Scarica la cartella'}>
            <span>
              <Button onClick={downloadPatientFolder} sx={{width: '6rem', height: '100%', background: '#3686D6', color: '#ffffff'}}
                      disabled={!folder?.images?.length}>
              <Download/>
            </Button>
            </span>
          </Tooltip>
          <Tooltip title={'Scarica il referto'}>
            <span>
              <Button onClick={downloadMedicalReport} sx={{width: '6rem', height: '100%', background: '#3686D6', color: '#ffffff'}}
                      disabled={!folder?.hasMedicalReport}>
              <SimCardDownload/>
            </Button>
            </span>
          </Tooltip>
          <Tooltip title={'Visualizzatore DICOM'}>
            <span>
              <Button onClick={(() => {
                const cookies = new Cookies()
                generateViewerToken()
                  .then((token) => {
                    cookies.set('viewerToken', token, {path: '/'})
                    window.open(`${VIEWER_URL}/dicom/viewer/$${folder.id}`, '_blank')
                  })
                  .catch((e) => logger.error(e))
              })} sx={{width: '6rem', height: '100%', background: '#3686D6', color: '#ffffff'}} disabled={!folder?.images?.length}>
              <Visibility/>
            </Button>
            </span>
          </Tooltip>
        </ButtonsContainer>
      </Header>
      <MedicalReportStatus>
        <div>Stato referto:</div>
        <div style={{fontWeight: 'bold'}}>{folder.hasMedicalReport ? 'Presente' : 'Non presente'}</div>
      </MedicalReportStatus>

      <table>
        <thead>
        <tr>
          <th>
            Identificativo del gruppo di immagini
          </th>
          <th>
            Data di creazione
          </th>
          <th>
            Tipo di esame
          </th>
        </tr>
        </thead>
        <tbody>
        {
          folder.images && Array.from(folder.images.reduce((studyMap, image) => {
            const existingImage = studyMap.get(image.studyUid)
            if (!existingImage) studyMap.set(image.studyUid, image)
            return studyMap
          }, new Map<string, Image>()).entries())
            .map((imageEntry) => {
              return (
                <tr key={imageEntry[0]}>
                  <td>
                    {imageEntry[0]}
                  </td>
                  <td>
                    {imageEntry[1].creationDate?.toLocaleString('it-IT', { timeZone: 'UTC' })}
                  </td>
                  <td>
                    {imageEntry[1].examType}
                  </td>
                </tr>
              )
            })
        }
        </tbody>
      </table>
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={downloading}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center'}}>
          <CircularProgress color="inherit"/>
          Download in corso, scaricati {downloadedMegabytes}MB
        </div>
      </Backdrop>
    </Container>
  )
}

export default PersonalArea

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const MedicalReportStatus = styled.div`
  display: flex;
  gap: 4px;
  font-size: 1.5rem;
  margin: 1rem 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px
`

const PatientName = styled.div`
  font-size: 2.5rem;
`