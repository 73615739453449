import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {setAccessToken} from '@store/actions'
import {useDispatch} from 'react-redux'
import {useRest} from '@hooks'
import {logger} from '@helpers'
import healthyQr from '@images/healthyQr.jpg'
import styled from 'styled-components'
import {Button, Tooltip} from '@mui/material'
import {Check, Password} from '@mui/icons-material'

const OTPAccess: React.FC = () => {
  const [params] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {post: submitOTP, get: getOTPMail} = useRest<string>('patient-folders/token')

  const [userToken, setUserToken] = useState<string>('')
  const [inputValue, setInputValue] = useState<string>('')
  const [otpGenerated, setOtpGenerated] = useState<boolean>(false)
  const [generateOTPDisabled, setGenerateOTPDisabled] = useState<boolean>(false)

  /* `handleClick` is a function that is created using the `useCallback` hook. It takes an event object as its argument and
  prevents the default behavior of the event. It then checks if `otpGenerated` is true, and if not, it returns. If
  `otpGenerated` is true, it calls the `submitOTP` function with an object containing the `otpCode` and a token, both
  obtained from state variables `inputValue` and `userToken`, respectively. If the `submitOTP` function is successful,
  it dispatches an action to set the access token and navigates to the personal area page. If there is an error, it logs
  the error using the `logger` helper function. The dependencies of the `useCallback` hook are `otpGenerated`,
  `userToken`, `inputValue`, `navigate`, `dispatch`, and `submitOTP`. This means that the function will only be
  recreated if any of these dependencies change. */
  const handleClick = useCallback((event: any) => {
    event.preventDefault()
    if (!otpGenerated) return

    submitOTP({otpCode: inputValue}, {token: userToken})
      .then(({payload}) => {
        dispatch(setAccessToken(payload))
        navigate('/personal-area')
      })
      .catch((error) => {
        logger.error(error)
      })

  }, [otpGenerated, userToken, inputValue, navigate, dispatch, submitOTP])

  /* `generateOTP` is a function that is created using the `useCallback` hook. It first checks if `userToken` is truthy,
  and if not, it returns. If `userToken` is truthy, it sets the state variable `generateOTPDisabled` to `true`. It then
  calls the `getOTPMail` function with an object containing the `userToken`. If the `getOTPMail` function is successful,
  it sets the state variable `otpGenerated` to `true`. If there is an error, it logs the error using the `logger` helper
  function. Finally, it returns a timeout function that sets the state variable `generateOTPDisabled` to `false` after
  10 seconds. The dependencies of the `useCallback` hook are `userToken`, `setOtpGenerated`, and `getOTPMail`. This
  means that the function will only be recreated if any of these dependencies change. */
  const generateOTP = useCallback(() => {
    if (!userToken) return

    setGenerateOTPDisabled(true)

    getOTPMail({token: userToken})
      .then(() => {
        setOtpGenerated(true)
      })
      .catch((error) => {
        logger.error(error)
      })

    return setTimeout(() => setGenerateOTPDisabled(false), 10000)

  }, [userToken, setOtpGenerated, getOTPMail])

  /* This `useEffect` hook is setting the state variable `userToken` based on the value of the `userToken` parameter in the
  URL search params. It runs whenever the `params` dependency changes. */
  useEffect(() => {
    setUserToken((oldUserToken) => {
      if (!params.get('userToken')) return ''
      if (oldUserToken !== params.get('userToken')) return params.get('userToken') || ''
      return oldUserToken
    })
  }, [params])

  /* This `useEffect` hook is checking if `otpGenerated` is false and if so, it calls the `generateOTP` function. The
  dependencies of the `useEffect` hook are `otpGenerated` and `generateOTP`, which means that the effect will run
  whenever either of these dependencies change. This effect is used to automatically generate a new OTP when the
  component mounts or when `otpGenerated` becomes false. */
  useEffect(() => {
    !otpGenerated && generateOTP()
  }, [otpGenerated, generateOTP])

  return (
    <Container>
      <img src={healthyQr}
           alt={'healthyQrLogo'}
      />
      <InputContainer>
        <FormField type="text" value={inputValue} style={{height: '100%'}} onChange={(event) => setInputValue(event.target.value)}/>
        <Tooltip title={'Invia'}>
          <span>
            <Button onClick={handleClick} onKeyDown={handleClick} sx={{background: '#3686D6', color: '#ffffff'}}>
            <Check/>
          </Button>
          </span>
        </Tooltip>
        <Tooltip title={'Genera nuovo OTP'}>
          <span>
            <Button onClick={generateOTP} disabled={generateOTPDisabled} sx={{background: '#3686D6', color: '#ffffff'}}>
            <Password/>
          </Button>
          </span>
        </Tooltip>
      </InputContainer>
      <div>
        Inserisci il codice OTP che ti abbiamo appena inviato via email per accedere alla cartella contenente
        gli esami*
      </div>
      <Disclaimer>
        *Proseguendo il paziente acconsente a condividere i dati relativi al proprio esame con il centro
        diagnostico ed eventualmente con uno specialista esterno
      </Disclaimer>
    </Container>
  )
}

export default OTPAccess

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  gap: 16px;
  height: 100%;
`

const FormField = styled.input`
  width: 15rem;
  height: 1.5rem;
  
`

const InputContainer = styled.div`
  display: flex;
  gap: 8px;
`

const Disclaimer = styled.div`
  margin: auto 0 38px 0;
`